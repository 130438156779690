import Vue from 'vue';
import { LiffListConfigInterface } from '@/components/list/types/list';
import Moment from 'moment'

class listConfig {
  protected vm?: Vue;
  protected listKey?: string;

  get(vm: Vue, listKey: string): LiffListConfigInterface {
    this.vm = vm;
    this.listKey = listKey;

    return {
      searchFilterOrder: 0,
      sortOrder: true,
      enable: {
        search: false,
      },
      targetKey: 'order_no',
      pageTitle: 'module.delivery',
      sort: [{ label: 'data.created_at', key: 'created_at' }],
      disableLoading: true,
      liffFilter: {
        date: {
          type: 'tab',
          options: () => {
            const deliveryTab = Array(7).fill(null).map((_, i)=> ({
              text: Moment().add(i, 'd').format('MM/DD'),
              value: Moment().add(i, 'd').format('YYYY-MM-DD')
            }))
            return deliveryTab;
          },
        },
      },
      listItem: () => import('@/modules/provider/views/delivery/deliveryManCardItem.vue'),
      useRow: false,
    };
  }
}

export default new listConfig();
