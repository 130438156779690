<template>
  <div>
    <v-container pt-2 pb-1>
      <page-title title="貨運列表" />
    </v-container>
    <liff-list :listKey="listKey" @sortEnd="sortEnd"></liff-list>
  </div>
</template>

<script lang="babel" type="text/babel">
import _cloneDeep from 'lodash/cloneDeep'
import listMixin from "@/components/list/mixins/listMixin.js"
import listConfig from "./deliveryManList"

export default {
  mixins: [listMixin],
  data: () => ({
    listKey: 'liff-provider-delivery-man-list',
    meta: {},
    indexApiInterval: null
  }),
  computed: {
    isInLiffClient() {
      return this.$helper.isInLiffClient();
    },
    providerId() {
      return this.$store.getters[`member/providerId`]
    },
    listFilter() {
      return this.$store.getters[`list/${this.listKey}/filter`]
    },
    status() {
      if(!this.listFilter) return null
      return this.listFilter.status
    },
    title() {
      if(!this.status) return ''
      return this.$t(`provider.status.${this.status}`)
    },
    listData() {
      return this.$store.getters[`list/${this.listKey}/data`]
    },
  },
  methods: {
    async beforeIndex() {
      await Promise.all([])
    },
    async indexApi(params) {
      const response = await this.$api.collection.providerApi.getDeliveryMans(this.providerId, params)
      response.data = response.data.sort((a, b) => a.delivery_sort - b.delivery_sort)
      return response
    },
    getListConfig() {
      return listConfig
    },
    async sortEnd(params) {
      const { orderId, delivery_sort } = params
      return await this.$api.collection.providerApi.sortDeliveryMans(this.providerId, orderId, {delivery_sort})
    },
  },
  mounted() {
    this.$root.gaLogEvent('供應商_貨運列表頁')
    this.indexApiInterval = setInterval(() => {
      if(this.$route.name !== 'provider-delivery-mans') {
        clearInterval(this.indexApiInterval)
        return
      }
      this.listActions.index('disableLoading')
    }, 15000)
  }
}
</script>

<style lang="sass" type="text/sass" scoped></style>
